define("discourse/plugins/discourse-templates/discourse/components/d-templates/filterable-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "select-kit/components/tag-drop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _service, _ajax, _ajaxError, _decorators, _tagDrop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="templates-filterable-list" {{did-insert this.load}}>
  
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      <div class="templates-filter-bar">
        {{#if this.siteSettings.tagging_enabled}}
          <DTemplates::TagDrop
            @availableTags={{this.availableTags}}
            @tagId={{this.selectedTag}}
            @onChangeSelectedTag={{this.changeSelectedTag}}
          />
        {{/if}}
        <TextField
          class="templates-filter"
          @value={{this.listFilter}}
          placeholder={{i18n "templates.filter_hint"}}
        />
      </div>
      <div class="templates-list">
        {{#each this.filteredReplies as |r|}}
          <DTemplates::Item
            @template={{r}}
            @model={{@model}}
            @onInsertTemplate={{this.insertTemplate}}
          />
        {{/each}}
      </div>
    </ConditionalLoadingSpinner>
  </div>
  */
  {
    "id": "RoIUcMzi",
    "block": "[[[11,0],[24,0,\"templates-filterable-list\"],[4,[38,1],[[30,0,[\"load\"]]],null],[12],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"templates-filter-bar\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@availableTags\",\"@tagId\",\"@onChangeSelectedTag\"],[[30,0,[\"availableTags\"]],[30,0,[\"selectedTag\"]],[30,0,[\"changeSelectedTag\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,5],[[24,0,\"templates-filter\"],[16,\"placeholder\",[28,[37,6],[\"templates.filter_hint\"],null]]],[[\"@value\"],[[30,0,[\"listFilter\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"templates-list\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"filteredReplies\"]]],null]],null],null,[[[1,\"        \"],[8,[39,9],null,[[\"@template\",\"@model\",\"@onInsertTemplate\"],[[30,1],[30,2],[30,0,[\"insertTemplate\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"r\",\"@model\"],false,[\"div\",\"did-insert\",\"conditional-loading-spinner\",\"if\",\"d-templates/tag-drop\",\"text-field\",\"i18n\",\"each\",\"-track-array\",\"d-templates/item\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/filterable-list.hbs",
    "isStrictMode": false
  });
  class DTemplatesFilterableList extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return true;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "listFilter", [_tracking.tracked], function () {
      return "";
    }))();
    #listFilter = (() => (dt7948.i(this, "listFilter"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "replies", [_tracking.tracked], function () {
      return [];
    }))();
    #replies = (() => (dt7948.i(this, "replies"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "selectedTag", [_tracking.tracked], function () {
      return _tagDrop.ALL_TAGS_ID;
    }))();
    #selectedTag = (() => (dt7948.i(this, "selectedTag"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "availableTags", [_tracking.tracked], function () {
      return [];
    }))();
    #availableTags = (() => (dt7948.i(this, "availableTags"), void 0))();
    get filteredReplies() {
      const filterTitle = this.listFilter.toLowerCase();
      return this.replies.map(template => {
        /* Give a relevant score to each template. */
        template.score = 0;
        if (template.title.toLowerCase().includes(filterTitle)) {
          template.score += 2;
        } else if (template.content.toLowerCase().includes(filterTitle)) {
          template.score += 1;
        }
        return template;
      })
      // Filter irrelevant replies.
      .filter(template => template.score !== 0)
      // Filter only replies tagged with the selected tag.
      .filter(template => {
        if (this.selectedTag === _tagDrop.ALL_TAGS_ID) {
          return true;
        }
        if (this.selectedTag === _tagDrop.NO_TAG_ID && template.tags.length === 0) {
          return true;
        }
        return template.tags.includes(this.selectedTag);
      }).sort((a, b) => {
        /* Sort replies by relevance and title. */
        if (a.score !== b.score) {
          return a.score > b.score ? -1 : 1; /* descending */
        } else if (a.title !== b.title) {
          return a.title < b.title ? -1 : 1; /* ascending */
        }
        return 0;
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "filteredReplies", [(0, _object.computed)("replies", "selectedTag", "listFilter")]))();
    async load() {
      try {
        this.loading = true;
        const results = await (0, _ajax.ajax)("/discourse_templates");
        this.replies = results.templates;
        if (this.siteSettings.tagging_enabled) {
          this.availableTags = Object.values(this.replies.reduce((availableTags, template) => {
            template.tags.forEach(tag => {
              if (availableTags[tag]) {
                availableTags[tag].count += 1;
              } else {
                availableTags[tag] = {
                  id: tag,
                  name: tag,
                  count: 1
                };
              }
            });
            return availableTags;
          }, {}));
        }
      } catch (e) {
        this.loading = false;
        (0, _ajaxError.popupAjaxError)(e);
      } finally {
        this.loading = false;
        (0, _runloop.schedule)("afterRender", () => document.querySelector(".templates-filter")?.focus());
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "load", [_decorators.bind]))();
    changeSelectedTag(tagId) {
      this.selectedTag = tagId;
    }
    static #_9 = (() => dt7948.n(this.prototype, "changeSelectedTag", [_object.action]))();
    insertTemplate(template) {
      this.args.onBeforeInsertTemplate?.();
      this.args.onInsertTemplate?.(template);
      this.args.onAfterInsertTemplate?.();
    }
    static #_10 = (() => dt7948.n(this.prototype, "insertTemplate", [_object.action]))();
  }
  _exports.default = DTemplatesFilterableList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DTemplatesFilterableList);
});